.valist { margin-top:8px; }
.valist > .search { margin-bottom:24px; }

.validators .node > .name { font-weight:600; font-size:16px; }
.validators .node > .pubkey { font-size:12px; opacity:0.75; }
.validators .node > .vote { font-size:12px; opacity:0.75; }
.validators .location > .ip { font-weight:bold; white-space: nowrap; }
.validators .location > .geo > img { height:12px; border-radius:2px; position:relative; margin-right:4px; top:1px; }
.validators .location > .geo { opacity:0.75; font-size:12px; }
.validators .stake > .commission { opacity:0.75; font-size:12px; }
.validators th { cursor: pointer; user-select: none; }
.validators tr { cursor: pointer; }
.validators tr > td:last-child { text-transform: capitalize; text-align:left;  }
.validators tr > td:last-child > .ant-btn-group { margin-top:5px; }
.validators tr.active > td:last-child { color:#a0d911; }
.validators tr.delinquent > td:last-child { color:#f5222d }

.rpcLinks > div + div { margin-top:8px; }
.rpcLinks > div > .name { opacity:0.5; }
.rpcLinks > div > span.copier { display: block; white-space: nowrap; border:1px dashed rgba(255,255,255,0.5); border-radius:3px; padding:2px 4px; position: relative; overflow: hidden; }
.rpcLinks > div > span.copier:after { content: ' '; position: absolute; top:0px; right:22px; width:60px; height:28px; z-index: 1; background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(29,29,29,1) 100%); }
.rpcLinks > div > span.copier:before { content: ' '; position: absolute; top:0px; right:0px; width:22px; height:28px; z-index: 1; background: rgba(29,29,29,1) }
.rpcLinks > div > span.copier > .anticon { position: absolute; top:6px; right:4px; opacity:.5; z-index:2; }


@media (max-width:981px)
{
    .validators .ant-table { overflow-x:auto; }
}