@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');


.wallet-adapter-button{ border-radius:12px !important; }   

* { box-sizing:border-box; font-family:'Inter',Roboto, sans-serif; }
.flex-wrap{display:flex;-ms-display:flex;-webkit-display:flex;-moz-display:flex;box-pack:justify;-webkit-box-pack:justify;-moz-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-moz-box-direction:normal;-moz-box-orient:horizontal;display:flex;flex-flow:row wrap}
.flex{display:flex;-ms-display:flex;-webkit-display:flex;-moz-display:flex;-moz-box-direction:normal;-moz-box-orient:horizontal;display:flex;flex-flow:row wrap}

.content { max-width:1250px !important; width:100% !important; padding:48px; margin:0px auto !important; }
footer { text-align:center;  padding:16px; font-size:12px; line-height: 1.75em; color:rgba(255,255,255,0.5); }


body { background:#101322; color:#fff;  overflow-x: hidden;}
a { color:#fff; }


.links { padding:16px 0px 24px 0px;}

.links > a > svg {  width:32px; height:32px; }
.links > a > svg path { transition: all 250ms; fill:#000; }
.links > a:hover > svg path { fill:#fff; }
.links > a {  display: inline-block; text-decoration: none; }
.links > a + a { margin-left:16px; }

.loading { text-align:center; min-height:200px; line-height:200px; }

.ant-form-item-control-input-content > .ant-alert { margin:8px 0px; }

.miniWidth { max-width:600px; margin:0px auto }

.tag { width:16px; height:12px; margin-right:6px;font-size:6px; line-height: 12px; background:rgba(255,255,255,0.1); border-radius:4px; display: inline-block; vertical-align: middle; text-align: center; font-weight: normal; }

.ant-modal-content, .ant-modal-body { background:#1d1d1d !important;  }
.ant-modal-confirm-paragraph, .ant-modal-confirm-paragraph * { color:#fff !important; }

.sol {
    width:32px; height:32px;
    text-align:center;
    background-size:32px 32px;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'  viewBox='0 0 203.18 203.18'%3E%3Cpath fill='#fff'  d='m202.13 154.01-33.55 35.97c-.72.78-1.6 1.4-2.58 1.83-.98.42-2.03.64-3.1.65H3.89a4.06 4.06 0 0 1-2.14-.64 3.908 3.908 0 0 1-1.43-1.71c-.31-.69-.4-1.46-.28-2.21.13-.74.47-1.44.99-2l33.51-35.96c.72-.78 1.6-1.41 2.58-1.83.97-.43 2.03-.65 3.09-.65h159.02c.76-.02 1.52.19 2.16.6a3.871 3.871 0 0 1 1.74 3.94c-.13.75-.48 1.45-1 2.01zm-33.55-72.45a7.876 7.876 0 0 0-5.68-2.48H3.89a3.89 3.89 0 0 0-3.57 2.34c-.31.7-.4 1.47-.28 2.21.13.75.47 1.45.99 2l33.51 35.99c.72.78 1.6 1.4 2.58 1.82.98.43 2.03.65 3.09.66h159.02a3.95 3.95 0 0 0 2.13-.65c.63-.41 1.13-1.01 1.43-1.7.3-.69.39-1.46.26-2.21a3.94 3.94 0 0 0-.98-1.99l-33.49-35.99zM3.89 55.72H162.9c1.07 0 2.12-.22 3.1-.65.98-.42 1.86-1.05 2.58-1.83l33.55-35.96c.52-.56.87-1.26 1-2.01a3.871 3.871 0 0 0-3.9-4.54H40.21c-1.06 0-2.12.22-3.09.64-.98.43-1.86 1.05-2.58 1.84L1.03 49.17c-.52.56-.86 1.25-.99 2-.12.75-.03 1.52.28 2.21.3.7.8 1.29 1.43 1.71.64.41 1.38.63 2.14.63z' /%3E%3C/svg%3E");
}

.button { background:#512da8; line-height:48px !important; font-weight:600; font-size:16px; height:48px; padding:0px 24px; transition: all 250ms; box-shadow: none !important; }
.button:hover { background:#333 !important;  }

@media (max-width:1150px)
{
    .content { padding:16px 16px 32px 16px; }
}


