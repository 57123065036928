.mapCircle:hover { fill:aquamarine !important; }

.map { border:1px solid rgba(255,255,255,0.1) !important; border-radius:12px; overflow: hidden; }
 img.flag { height:12px; border-radius:2px; position:relative; margin-right:4px; top:1px; }
.validators-geo .ant-table-wrapper { margin-top:16px; }

.activeNodes { font-weight: bold; }
.delinquentNodes { font-size:12px; opacity:0.5; }

.nodesDrawer { width:100%; }
@media (min-width: 1400px)
{
    .nodesDrawer { width:1400px; }
}